.drive-modal{
    overflow-y: hidden;
    .modal-content{
        border-radius: 0px;
        height: 100vh;
        .card{
            height: 100%;
            .card-body{
                height: 100%;
                overflow-y: scroll;
                .client-view{
                    height: 100%;
                    button{
                        float: right;
                        margin: 0px 0px 0px 0px;
                    }
                    iframe{
                        margin-top: 1vh;
                        height: 100%;
                        width: 100%;    
                    }

                }
                    
            }
        }
    }
}
.sortable{
    font-size:large;
    opacity: 2000;
}